html,
body,
#root {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overscroll-behavior: none;
  overflow: hidden;
}
* {
  font-family: 'Gotham', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}
